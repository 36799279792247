import React from "react";
import Laptop from "../Images/build.png";

function index() {
  return (
    <>
      <div className="small-headline">
        Start with a blank canvas.
        <br />
        End with interactive ideas.
      </div>
      <p className="center-paragraph">
        Patera creates alternative ways of writing for active readers. By adding
        variables and calculations directly into your document, blog, article or
        research paper you can allow readers to interact with your ideas by
        changing values and sketching scenarios.
      </p>
      <img alt="" className="laptop" src={Laptop} />
    </>
  );
}

export default index;
