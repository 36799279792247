import React from "react";

function index() {
  return (
    <div className="intro-container-light">
      <div className="intro-headline gradient light-headline">
        Text shouldn't just be static.<br></br>
        <br></br>
        Patera provides deeper understanding through context and exploration.
      </div>
    </div>
  );
}

export default index;
