import React from "react";
function index() {
  return (
    <>
      <div id="mc_embed_signup">
        <form
          action="https://patera.us5.list-manage.com/subscribe/post?u=75c66fe007f89a5ff0fb5a134&amp;id=152bc36e1a"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          className="signup-form-container"
          target="_blank"
          rel="noreferrer"
          novalidate
        >
          <div class="mc-field-group">
            <input
              type="email"
              placeholder="Enter your email"
              name="EMAIL"
              class="required email"
              className="signup-form-input"
              id="mce-EMAIL"
            />
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response"></div>
            <div class="response" id="mce-success-response"></div>
          </div>
          <div class="clear">
            <input
              type="submit"
              value="Get early access"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
              className="signup-form-button desktop"
            />
            <input
              type="submit"
              value="Sign up"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
              className="signup-form-button mobile"
            />
          </div>
        </form>
        <span className="signup-hint">
          Sign up to get early access when we launch.
        </span>
      </div>
    </>
  );
}

export default index;
