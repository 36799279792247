import React from "react";

const today = new Date();

function index() {
  return (
    <div className="footer-container">
      <div className="footer-inner-container">
        <span className="footer-span">Patera</span>
        <a
          href="https://twitter.com/PateraHQ"
          target="_blank"
          className="footer-span"
          rel="noreferrer"
        >
          Twitter ↗
        </a>
        <span className="footer-span">© {today.getFullYear()}</span>
      </div>
    </div>
  );
}

export default index;
