import React from "react";
import Laptop from "../Images/var.png";

function index() {
  return (
    <div className="container">
      <div className="text-container">
        <div className="left small-headline">Make documents dynamic.</div>
        <p className="left center-paragraph">
          Static documents are still dominant.<br></br>
          <br></br> Research papers, audits, political forecasts, marketing
          plans and many more are presented through hundreds of pages of text.
          <br></br>
          <br></br>With Patera, your dynamic document summarizes these pages
          into comprehensible and playful presentations.
        </p>
      </div>
      <img alt="" className="right laptop" src={Laptop} />
    </div>
  );
}

export default index;
