import React from "react";
import Laptop from "../Images/drag.svg";

function index() {
  return (
    <div className="container">
      <div className="text-container">
        <div className="left small-headline">Explore possibilities.</div>
        <p className="left center-paragraph">
          When you make a number a variable in Patera, these values are now
          draggable. Add calculations in excel syntax elsewhere in the document
          to see these update in real time.
        </p>
      </div>
      <img alt="" className="right laptop" src={Laptop} />
    </div>
  );
}

export default index;
