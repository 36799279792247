import React from "react";
import Laptop from "../Images/graph.svg";

function index() {
  return (
    <div className="container">
      <div className="text-container">
        <div className="coming-soon">Coming soon</div>
        <div className="left small-headline">Explore visually.</div>
        <p className="left center-paragraph">
          Build instant graphs and visualizations that team members, readers or
          stakeholders can interact with, drag, update and explore.
        </p>
      </div>
      <img alt="" className="right laptop" src={Laptop} />
    </div>
  );
}

export default index;
