import React from "react";
import Laptop from "../Images/calc.png";

function index() {
  return (
    <div className="container">
      <div className="text-container">
        <div className="left small-headline">No more A4 to A36.</div>
        <p className="left center-paragraph">
          Allow readers to click on numbers to reveal how they're calculated. In
          Patera, you can use plain recognizable language to form powerful
          formulas.
        </p>
      </div>
      <img alt="" className="right laptop" src={Laptop} />
    </div>
  );
}

export default index;
