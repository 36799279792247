import React from "react";
import Video from "../Images/demo.mp4";
function index() {
  return (
    <div className="hero-container">
      <div className="headline">
        Bring documents
        <br />
        to life.
      </div>
      <div className="laptop video">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="video-inner"
          type="video/mp4"
          src={Video}
        />
      </div>
    </div>
  );
}

export default index;
