import React from "react";
import navLogo from "./navLogo.svg";

function Nav() {
  return (
    <div className="nav-bar">
      <img alt="" className="navLogo" src={navLogo} />
      <a href="#mc_embed_signup" className="get-notified">
        Sign up
      </a>
    </div>
  );
}

export default Nav;
