import React from "react";
import Laptop from "../Images/db.png";

function index() {
  return (
    <div className="container">
      <div className="text-container">
        <div className="coming-soon">Coming soon</div>
        <div className="left small-headline">Add data.</div>
        <p className="left center-paragraph">
          Whatever you need to track, add data to your documents that
          dynamically update when things change. No more jumping between
          documents. Always have access to realtime data.
        </p>
      </div>
      <img alt="" className="right laptop" src={Laptop} />
    </div>
  );
}

export default index;
