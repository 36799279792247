import "./App.css";
import Nav from "./Nav";
import Gap from "./Gap";
import SmallGap from "./SmallGap";
import Headline from "./Headline";
import Intro from "./Intro";
import TwoColumnFeature from "./TwoColumnFeature";
import TwoColumnFeatureTwo from "./TwoColumnFeatureTwo";
import TwoColumnFeatureThree from "./TwoColumnFeatureThree";
import TwoColumnFeatureFour from "./TwoColumnFeatureFour";
import TwoColumnFeatureFive from "./TwoColumnFeatureFive";
// import Switcher from "./Switcher";
import TextIntro from "./TextIntro";
import Why from "./Why";
import Signup from "./Signup";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 0, // offset (in px) from the original trigger point
  delay: 50, // values from 0 to 3000, with step 50ms
  duration: 700, // values from 0 to 3000, with step 50ms
  easing: "ease-out-quad", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: true // whether elements should animate out while scrolling past them
  // anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
});

function App() {
  return (
    <>
      <Nav />
      <div data-aos="fade-up">
        <Headline />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <Signup />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <TextIntro />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <Intro />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <Why />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <Signup />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <TwoColumnFeature />
      </div>
      <SmallGap />
      <div data-aos="fade-up">
        <TwoColumnFeatureTwo />
      </div>
      <SmallGap />
      <div data-aos="fade-up">
        <TwoColumnFeatureThree />
      </div>
      <SmallGap />
      <div data-aos="fade-up">
        <TwoColumnFeatureFour />
      </div>
      <SmallGap />
      <div data-aos="fade-up">
        <TwoColumnFeatureFive />
      </div>
      <Gap />
      <div data-aos="fade-up">
        <Signup />
      </div>
      <Gap />
      {/* <Switcher /> */}
      <Footer />
    </>
  );
}

export default App;
