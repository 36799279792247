import React from "react";
import Logomark from "../Images/logomark.svg";

function index() {
  return (
    <div className="intro-container">
      <img alt="" src={Logomark} className="logomark" />
      <div className="intro-headline">
        Patera is an environment for teams to think, plan, and model scenarios
        in, allowing you to throw out the boring, endless sheets of paper and
        present dynamic documents.
        <br></br>
        <br></br>
        By using excel syntax you can explore possibilities, outcomes and
        alternatives.<br></br>
        <br></br>Like a spreadsheet you can actually read.
      </div>
    </div>
  );
}

export default index;
